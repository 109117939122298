<div id="modal_aviso_cookies" uk-modal>
    <div class="uk-modal-dialog">
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="uk-modal-body">
            <h2 class="uk-modal-title">Aviso de uso de cookies</h2>
            <div uk-overflow-auto>
                <h4 class="dark">¿Qué son las cookies?</h4>
                <p class="dark">
                    Una cookie es un fichero que se descarga en tu ordenador al acceder a determinadas páginas web. Las cookies permiten a una página web, entre otras cosas, almacenar y recuperar información sobre los hábitos de navegación de un usuario o de su equipo y, dependiendo de la información que contengan y de la forma en que utilice el equipo, pueden utilizarse para reconocer al usuario.
                </p>
                
                <h4 class="dark">¿Qué tipos de cookies utilizamos?</h4>
                <p class="dark">
                    Cookies de análisis. Son aquéllas que bien tratadas por nosotros o por terceros, nos permiten cuantificar el número de usuarios y así realizar la medición y análisis estadístico de la actividad. Para ello se analiza su navegación en nuestra página web con el fin de mejorar.
                </p>
                
                <h4 class="dark">Cómo desactivar las cookies</h4>
                <p class="dark">
                    Puedes permitir, bloquear o eliminar las cookies instaladas en tu equipo mediante la configuración de las opciones del navegador en tu ordenador.
                </p>
                
                <h4 class="dark">Cookies de terceros</h4>
                <p class="dark">
                    Nuestra página web utiliza servicios de terceros para recopilar información con fines estadísticos y de uso de la web.
                </p>
                
                <h4 class="dark">Advertencia sobre eliminar cookies</h4>
                <p class="dark">
                    Puedes eliminar y bloquear todas las cookies de nuestro sitio, pero parte del sitio no funcionará o la calidad de la página web puede verse afectada.
                </p>
                
                <p class="dark">
                    Si tienes cualquier duda acerca de nuestra política de cookies, puedes contactarnos a través de: contactanos@smartvoice.com.mx   
                </p>
            </div>
            <br>
            <div class="uk-text-right">
                <button class="uk-button green uk-border-pill white-text uk-modal-close" type="button">Aceptar</button>
            </div>
        </div>
    </div>
</div>