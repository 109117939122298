<div>
    <nav class="uk-navbar uk-navbar-container uk-navbar-ligth" data-uk-navbar>
        <div class="uk-navbar-left navi">
            <div class="uk-navbar-item">
                <!--<a class="uk-hidden@m" href="#menu_left" uk-toggle><span class="dark-text" uk-navbar-toggle-icon></span></a>-->
                <a (click)="openNav()"><span class="dark" uk-navbar-toggle-icon></span></a>
                <div class="uk-container">
                    <a class="uk-logo" title="Smartvoice" href="/"><img
                            src="../assets/images/icons/LogoSmartvoice/logo@3x.png" alt="Smartvoice"></a>
                </div>
            </div>
        </div>


        <!--<div class="uk-navbar-right uk-margin-large-right ">
            <ul class="uk-navbar-nav uk-visible@m">
                <li class="item">
                    <a href="/" gaEvent="click" gaCategory="FAQS_MENU_HOME" class="dark-text"><span>Home</span></a></li>
                <li class="item">
                    <a gaEvent="click" gaCategory="FAQS_MENU_CARACTERISTICAS" href="/#caracteristicas" gaEvent="click" gaCategory="MENU_CARACTERISTICAS" class="dark-text"><span>Características</span></a></li>
                <li class="item">
                    <a gaEvent="click" gaCategory="FAQS_MENU_COMOFUNCIONA" class="dark-text" href="/#function">
                        <span>¿Cómo funciona?</span></a></li>
                <li class="uk-active">
                    <a href="#" class="dark-text" style="font-weight: bold;">Preguntas frecuentes</a>
                </li>
            </ul>
        </div>-->

        <div id="menu_left" uk-offcanvas="overlay: true; mode: push">
            <div class="uk-offcanvas-bar">
                <div class="header">
                    <p>Menú</p>
                    <button class="uk-offcanvas-close" type="button" uk-close></button>
                </div>
                <ul class="uk-nav uk-nav-primary">
                    <li><a gaEvent="click" gaCategory="FAQS_MENU_HOME" href="/"><i class="uk-margin-right uil uil-home"></i>
                            <span class="uk-margin-left uk-text-small uk-text-bold">Home</span> </a></li>
                    <li><a gaEvent="click" gaCategory="FAQS_MENU_CARACTERISTICAS" href="/#caracteristicas"><i class="uk-margin-right uil uil-clipboard-notes"></i> <span
                                class="uk-margin-left uk-text-small uk-text-bold">Características</span></a></li>
                    <li><a gaEvent="click" gaCategory="FAQS_MENU_COMOFUNCIONA" href="/#function"><i class="uk-margin-right uil uil-question-circle"></i> <span
                                class="uk-margin-left uk-text-small uk-text-bold">¿Cómo funciona?</span></a></li>
                    <li><a (click)="closeNav()"><i class="uk-margin-right uil uil-comment-alt-notes"></i> <span
                                class="uk-margin-left uk-text-small uk-text-bold">Preguntas frecuentes</span></a>
                    </li>
                    <li><a gaEvent="click" gaCategory="REGISTER_MENU_PRECIOS" (click)="toPrices()"><i class="uk-margin-right uil uil-coins"></i> <span
                        class="uk-margin-left uk-text-small uk-text-bold">Precios</span></a>
                    </li>
                    <li class="item">
                        <a gaEvent="click" gaCategory="MENU_CONTACT" href="/register"><i class="uk-margin-right uil uil-comment-alt"></i><span
                                class="uk-margin-left uk-text-small uk-text-bold">Contactanos</span></a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</div>
<section class="uk-section uk-section-default content-circle">
    <div class="uk-container uk-container-xsmall uk-text-center uk-section uk-padding-remove-top">
        <h2 class="uk-margin-remove uk-h1">Preguntas frecuentes</h2>
        <h5>Es probable que todavía estés en la disyuntiva de implementar o no Smartvoice a tu comercio.
            Sabemos algunas de las preguntas que aún te inquietan, así que te daremos
            algunas respuestas para guiarte en tu decisión.</h5>
    </div>
    <div class="uk-container uk-container-xsmall white uk-box-shadow-small "
        style="padding-top: 20px; padding-bottom: 20px;">
        <ul uk-accordion class=" uk-list-divider">
            <li class="uk-open">
                <a class="uk-accordion-title" href="#">¿Qué es Smartvoice?</a>
                <div class="uk-accordion-content uk-text-center">
                    <p>Smartvoice es una plataforma de comercio electrónico en el cual puedes realizar ventas a través
                        de los asistentes de voz y chatbot más utilizados en todo el mundo, no necesitas una aplicación
                        y tampoco necesitas un portal web para comenzar a generar ingresos.</p>
                </div>
            </li>
            <li>
                <a class="uk-accordion-title" href="#">¿Con qué canales es compatible Smartvoice?</a>
                <div class="uk-accordion-content">
                    <p>Es compatible con los siguientes canales:<br>
                        Whatsapp, Facebook Messenger, Telegram, Amazon Alexa, Google Assistant en Google Home, Teléfonos
                        Android y Android Auto.</p>
                </div>
            </li>
            <li>
                <a class="uk-accordion-title" href="#">¿Necesito un Bot para cada canal?</a>
                <div class="uk-accordion-content">
                    <p>
                        Es correcto, al ser Smartvoice una plataforma omnicanal y con el propósito de ofrecer más de un
                        medio de comercio electrónico, permite a los clientes realizar compras a través de casi todas
                        las vías posibles, lo cual quiere decir que es una solución de comercio electrónico multicanal,
                        por ello es posible que el usuario final elija el bot de su preferencia para realizar compras o
                        vender a sus clientes.
                    </p>
                </div>
            </li>
            <li>
                <a class="uk-accordion-title" href="#">¿Por qué necesito un asistente como Smartvoice?</a>
                <div class="uk-accordion-content">
                    <p>
                        Necesitas una plataforma como Smartvoice para incrementar tus ventas, esta comprobado que al
                        realizar comercio electrónico guiado por Bots a través de una interfaz conversacional brinda una
                        mejor experiencia de compra, las ventas son repetitivas por este medio y como resultado existe
                        un incremento en tus ventas.
                    </p>
                </div>
            </li>
            <li>
                <a class="uk-accordion-title" href="#">¿Qué módulos puedo incorporar en Smartvoice?</a>
                <div class="uk-accordion-content">
                    <p>
                        Te apoyamos incluyendo los siguientes módulos dentro de Smartvoice: <br>
                    </p>
                    <ul class="list">
                        <li>Catálogos</li>
                        <li>Inventarios</li>
                        <li>Crédito</li>
                        <li>Plataforma de pagos</li>
                        <li>Creación de portal</li>
                    </ul>
                </div>
            </li>
            <li>
                <a class="uk-accordion-title" href="#">¿Qué integraciones puedo agregar?</a>
                <div class="uk-accordion-content">
                    <p>Estas son algunas de las integraciones posibles con Smartvoice, para hacer crecer tu negocio</p>
                    <ul class="list">
                        <li>Portal eCommerce Magento</li>
                        <li>Paquetería</li>
                        <li>Gestión de recursos e inventarios (ERP)</li>
                        <li>Helpdesk</li>
                    </ul>
                    <p>Conoce más acerca de las integraciones de Smartvoice: <a href="#modal_preview" uk-toggle
                            class="green-text">Ver detalles</a></p>
                </div>
            </li>
        </ul>
    </div>
    <div class="uk-container uk-container-xsmall white uk-margin-top">
        <div class="uk-text-center" uk-grid>
            <div class="uk-width-expand@m">
                <div uk-scrollspy="cls: uk-animation-slide-left; repeat: true">
                    <p class="subtitle-text dark">
                        ¿Tienes más dudas?
                    </p>
                    <a routerLink="/register" class="white-text uk-button green uk-border-pill uk-text-bold">CONTÁCTANOS</a>
                </div>
            </div>
            <div class="uk-width-auto@m">
                <div uk-scrollspy="cls: uk-animation-slide-right; repeat: true">
                    <img src="../../../assets/images/bot/bots-02.png" data-src="../../../assets/images/bot/bots-02.png"
                        data-uk-img alt="Image">
                </div>
            </div>
        </div>
    </div>
</section>

<!-- This is the modal -->
<div id="modal_preview" class="uk-modal-container " uk-modal>
    <div class="uk-modal-dialog uk-modal-body">
        <h2 class="uk-modal-title green-text uk-text-bold">Integración</h2>
        <div class="uk-grid" data-uk-grid
            data-uk-scrollspy="target: > div > div; delay: 150; cls: uk-animation-slide-top-medium">
            <div class="uk-grid uk-child-width-1-2@m">
                <div class="uk-text-left">
                    <h4 class="uk-margin-small-bottom uk-margin-top uk-margin-remove-adjacent">
                        Portal eCommerce Magento:
                    </h4>
                    <ul class="list">
                        <li>Incremento de ganancias.</li>
                        <li>Captación de nuevos clientes.</li>
                        <li>Optimizar procesos de venta.</li>
                        <li>Plataforma de pago.</li>
                        <li>Asociación con Smartvoice.</li>
                    </ul>
                </div>
                <div class="uk-text-left">
                    <h4 class="uk-margin-small-bottom uk-margin-top uk-margin-remove-adjacent">
                        Gestión de recursos e inventarios (ERP):
                    </h4>
                    <ul class="list">
                        <li>Automatización de la gestión.</li>
                        <li>La información que reside en tu ERP acerca de la disponibilidad de stock, precios y toda la
                            información de tus productos se refleja de forma automática en tu eCommerce y tus ventas.
                        </li>
                    </ul>
                </div>
            </div>
            <div class="uk-grid uk-child-width-1-2@m">
                <div class="uk-text-left">
                    <h4 class="uk-margin-small-bottom uk-margin-top uk-margin-remove-adjacent">
                        Paquetería:
                    </h4>
                    <ul class="list">
                        <li>Automatización de los envios.</li>
                        <li>Envío automático con toda la información de los pedidos del eCommerce hacía la empresa de
                            mensajería de tu preferencia.</li>
                        <li>Seguimiento a detalle del proceso de entrega, desde que sale el producto hasta el momento en
                            que lo recibe el cliente.</li>
                    </ul>
                </div>
                <div class="uk-text-left">
                    <h4 class="uk-margin-small-bottom uk-margin-top uk-margin-remove-adjacent">
                        Helpdesk:
                    </h4>
                    <ul class="list">
                        <li>Centralización y simplificación de la mesa de ayuda.</li>
                        <li>Convierte las consultas de soporte al cliente en tickets de servicio para un mejor seguimiento.</li>
                        <li>Con una gestión centralizada, fácil y rápida minimizas el impacto negativo que puede conllevar la mala experiencia de un cliente al difundirla por redes sociales y chats.</li>
                    </ul>
                </div>
            </div>
        </div>

        <p class="uk-text-right">
            <button class="uk-button green uk-border-pill white-text uk-modal-close" type="button">Aceptar</button>
        </p>
    </div>
</div>