import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aviso-cookies',
  templateUrl: './aviso-cookies.component.html',
  styleUrls: ['./aviso-cookies.component.css']
})
export class AvisoCookiesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
