import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Smartvoice';

  constructor( private router: Router, private $gaService: GoogleAnalyticsService) {
    this.router.events.subscribe(event => {
      if(event instanceof NavigationEnd){
        var name = '';
        switch (event.urlAfterRedirects) {
          case '/register':
            name = 'REGISTRO'
            break;
          case '/ventas':
            name = 'VENTAS'
            break;  
          case '/faqs':
            name = 'PREGUNTAS FRECUENTES'
            break;
          case '/#function':
            name = 'PÁGINA DE INICIO - como funciona -'
            break;
          case '/#home':
            name = 'PÁGINA DE INICIO - HOME -'
            break;
          case '/#caracteristicas':
            name = 'PÁGINA DE INICIO - caracteristicas -'
            break;
          case '/#price':
            name = 'PÁGINA DE PRECIOS'
            break;
          case '/':
            name = 'PÁGINA DE INICIO'
            break;
          default:
            name = 'PÁGINA DE INICIO'
            break;
        }
        this.$gaService.pageView(
          event.urlAfterRedirects, 
          name)
      }
    })
  }
}
