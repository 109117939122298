<div>
    <nav class="uk-navbar uk-navbar-container uk-navbar-ligth" data-uk-navbar>
        <div class="uk-navbar-left navi">
            <div class="uk-navbar-item">
                <!--<a class="uk-hidden@m" href="#menu_left" uk-toggle><span class="dark-text" uk-navbar-toggle-icon></span></a>-->
                <a (click)="openNav()"><span class="dark" uk-navbar-toggle-icon></span></a>
                <div class="uk-container">
                    <a class="uk-logo" title="Smartvoice" href="/"><img
                            src="../assets/images/icons/LogoSmartvoice/logo@3x.png" alt="Smartvoice"></a>
                </div>
            </div>
        </div>

        <div id="menu_left" uk-offcanvas="overlay: true; mode: push">
            <div class="uk-offcanvas-bar">
                <div class="header">
                    <p>Menú</p>
                    <button class="uk-offcanvas-close" type="button" uk-close></button>
                </div>
                <ul class="uk-nav uk-nav-primary">
                    <li><a gaEvent="click" gaCategory="REGISTER_MENU_HOME" href="/"><i class="uk-margin-right uil uil-home"></i>
                            <span class="uk-margin-left uk-text-small uk-text-bold">Home</span> </a></li>
                    <li><a gaEvent="click" gaCategory="REGISTER_MENU_CARACTERISTICAS" href="/#caracteristicas"><i class="uk-margin-right uil uil-clipboard-notes"></i> <span
                                class="uk-margin-left uk-text-small uk-text-bold">Características</span></a></li>
                    <li><a gaEvent="click" gaCategory="REGISTER_MENU_COMOFUNCIONA" href="/#function"><i class="uk-margin-right uil uil-question-circle"></i> <span
                                class="uk-margin-left uk-text-small uk-text-bold">¿Cómo funciona?</span></a></li>
                    <li><a gaEvent="click" gaCategory="REGISTER_MENU_FAQS" (click)="toFaqs()"><i class="uk-margin-right uil uil-comment-alt-notes"></i> <span
                                class="uk-margin-left uk-text-small uk-text-bold">Preguntas frecuentes</span></a>
                    </li>
                    <li><a gaEvent="click" gaCategory="REGISTER_MENU_PRECIOS" (click)="toPrices()"><i class="uk-margin-right uil uil-coins"></i> <span
                        class="uk-margin-left uk-text-small uk-text-bold">Precios</span></a>
                    </li>
                    <li class="item">
                        <a gaEvent="click" gaCategory="MENU_CONTACT" href="/register"><i class="uk-margin-right uil uil-comment-alt"></i><span
                                class="uk-margin-left uk-text-small uk-text-bold">Contactanos</span></a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</div>

<div class="uk-section-default content-circle" id="container">
    <div class="uk-section">
        <div class="uk-container uk-container-xsmall uk-text-center">
            <h2 class="title-section">Precios</h2>
            <h3 class="description-section">Implementa Smartvoice en tu comercio. Brinda a tus clientes una mejor experiencia de compra, 
                y obten un incremento en tus ventas a través de una solución innovadora y de bajo costo.</h3>
        </div>
        <div class="uk-container uk-container-xsmall uk-text-center uk-margin-large-top">
            <div class="content-relative">
                <table class="uk-table uk-table-striped">
                    <thead class="background-head">
                        <tr>
                            <th colspan="2" class="title-table-prices"><div class="cnt-contrato">Contrato considerado de 24 meses</div></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="subtitle-column">Pago para iniciar operación</td>
                            <td><span class="description-section">$2,500.00</span><br> <span class="subt-condition"><del>$3,998.00</del></span></td>
                        </tr>
                        <tr class="background-par">
                            <td class="subtitle-column">Mensualidad PYME (Anticipado)</td>
                            <td><span class="description-section">$2,000.00</span><br> <span class="subt-condition"><del>$3,100.00</del></span></td>
                        </tr>
                        <tr>
                            <td class="subtitle-column">Costo por transacción*</td>
                            <td><span class="description-section">5%</span></td>
                        </tr>
                        <tr class="background-head">
                            <td colspan="2">
                                <span class="title-table-prices">Incluye asistente de voz, chatbot y plataforma eCommerce</span><br>
                                <span class="subt-condition">(Google Assistant, Alexa, WhatsApp y Magento Commerce)</span>
                            </td>
                        </tr>
                        <tr class="background-graydark">
                            <td colspan="2">
                                <div class="content-description">
                                    Costo por no transaccionar: $250.00. <br>
                                    Los costos son plasmados en pesos mexicanos y no incluyen IVA. <br>
                                    *No incluye descuento.
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="content-img-absolute">
                    <div class="image-flex">
                        <p class="center-text-image">
                            <label class="text-amount-desc">50%</label><br>
                            <label class="text-descuento-descr">de descuento</label></p>
                        <img class="image-descuento" alt="Descuento" data-src="../../../assets/images/prices/oferta.png" uk-img>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="uk-container uk-container-xsmall white uk-margin-top uk-margin-medium-bottom">
    <div class="uk-text-center" uk-grid>
        <div class="uk-width-expand@m content-general-free">
            <div class="content-free" uk-scrollspy="cls: uk-animation-slide-left; repeat: true">
                <p class="subtitle-text dark">
                    ¡Aprovecha la oferta de lanzamiento!<br>
                    Te invitamos a una Demo para conocer más.
                </p>
                <a href="https://store.smartvoice.com.mx" target="_blank" class="white-text uk-button green uk-border-pill uk-text-bold">OBTÉN
                    30 DÍAS GRATIS</a>
            </div>
        </div>
        <div class="uk-width-auto@m">
            <div uk-scrollspy="cls: uk-animation-slide-right; repeat: true">
                <img src="../../../assets/images/bot/ventas/group-3.png" data-src="../../../assets/images/bot/ventas/group-3.png"
                    data-uk-img alt="Image">
            </div>
        </div>
    </div>
</div>