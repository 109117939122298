import { Component, OnInit } from '@angular/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { Router } from '@angular/router';

declare var UIkit: any;

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.css']
})
export class FaqsComponent implements OnInit {

  constructor(private router: Router) { }


  ngOnInit(): void {
  }

  openNav(){
    UIkit.offcanvas("#menu_left").show();
  }

  closeNav(){
    UIkit.offcanvas("#menu_left").hide();
  }
  toPrices(){
    UIkit.offcanvas("#menu_left").hide();
    setTimeout(() => {
      this.router.navigate(['prices']);
    }, 360);
  }

}
