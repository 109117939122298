<app-aviso-privacidad></app-aviso-privacidad>
<!--Modal-->
<div id="modal" uk-modal>
    <div class="uk-modal-dialog uk-modal-body uk-align-center uk-text-center" *ngIf="!sendEmail">
        <span uk-spinner="ratio: 4.5"></span>
    </div>
    <div class="uk-modal-dialog uk-modal-body" *ngIf="sendEmail">
        <h2 class="uk-modal-title">Recibimos tu mensaje correctamente.</h2>
        <p>En breve te contactaremos. Podrás hacer uso de tus 30 días gratis en muy poco tiempo una vez que lancemos
            Smartvoice al mercado.</p>
        <p class="uk-text-right">
            <button class="uk-button green uk-border-pill white-text uk-modal-close" type="button">Aceptar</button>
        </p>
    </div>
</div>
<div>
    <nav class="uk-navbar uk-navbar-container uk-navbar-ligth" data-uk-navbar>
        <div class="uk-navbar-left navi">
            <div class="uk-navbar-item">
                <!--<a class="uk-hidden@m" href="#menu_left" uk-toggle><span class="dark-text" uk-navbar-toggle-icon></span></a>-->
                <a (click)="openNav()"><span class="dark" uk-navbar-toggle-icon></span></a>
                <div class="uk-container">
                    <a class="uk-logo" title="Smartvoice" href="/"><img
                            src="../assets/images/icons/LogoSmartvoice/logo@3x.png" alt="Smartvoice"></a>
                </div>
            </div>
        </div>

        <!--<div class="uk-navbar-right uk-margin-large-right ">
            <ul class="uk-navbar-nav uk-visible@m">
                <li class="item">
                    <a href="/" gaEvent="click" gaCategory="REGISTER_MENU_HOME" class="dark-text"><span>Home</span></a></li>
                <li class="item">
                    <a gaEvent="click" gaCategory="REGISTER_MENU_CARACTERISTICAS" href="/#caracteristicas" gaEvent="click" gaCategory="MENU_CARACTERISTICAS" class="dark-text"><span>Características</span></a></li>
                <li class="item">
                    <a gaEvent="click" gaCategory="REGISTER_MENU_COMOFUNCIONA" class="dark-text" href="/#function">
                        <span>¿Cómo funciona?</span></a></li>
                <li>
                    <a gaEvent="click" gaCategory="REGISTER_MENU_FAQS" href="/faqs" class="dark-text">Preguntas frecuentes</a>
                </li>
            </ul>
        </div>-->

        <div id="menu_left" uk-offcanvas="overlay: true; mode: push">
            <div class="uk-offcanvas-bar">
                <div class="header">
                    <p>Menú</p>
                    <button class="uk-offcanvas-close" type="button" uk-close></button>
                </div>
                <ul class="uk-nav uk-nav-primary">
                    <li><a gaEvent="click" gaCategory="REGISTER_MENU_HOME" href="/"><i class="uk-margin-right uil uil-home"></i>
                            <span class="uk-margin-left uk-text-small uk-text-bold">Home</span> </a></li>
                    <li><a gaEvent="click" gaCategory="REGISTER_MENU_CARACTERISTICAS" href="/#caracteristicas"><i class="uk-margin-right uil uil-clipboard-notes"></i> <span
                                class="uk-margin-left uk-text-small uk-text-bold">Características</span></a></li>
                    <li><a gaEvent="click" gaCategory="REGISTER_MENU_COMOFUNCIONA" href="/#function"><i class="uk-margin-right uil uil-question-circle"></i> <span
                                class="uk-margin-left uk-text-small uk-text-bold">¿Cómo funciona?</span></a></li>
                    <li><a gaEvent="click" gaCategory="REGISTER_MENU_FAQS" (click)="toFaqs()"><i class="uk-margin-right uil uil-comment-alt-notes"></i> <span
                                class="uk-margin-left uk-text-small uk-text-bold">Preguntas frecuentes</span></a>
                    </li>
                    <li><a gaEvent="click" gaCategory="REGISTER_MENU_PRECIOS" (click)="toPrices()"><i class="uk-margin-right uil uil-coins"></i> <span
                        class="uk-margin-left uk-text-small uk-text-bold">Precios</span></a>
                    </li>
                    <li class="item">
                        <a gaEvent="click" gaCategory="MENU_CONTACT" href="/register"><i class="uk-margin-right uil uil-comment-alt"></i><span
                                class="uk-margin-left uk-text-small uk-text-bold">Contactanos</span></a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</div>
<!--Contenido-->
<div class="uk-grid-collapse uk-child-width-1-2@s uk-flex-middle" id="container" uk-grid>
    <div id="bot">
        <img src="../../../assets/images/ventas/smarti/image.png" alt="" srcset="">
    </div>
    <div class="uk-child-width-1-2@s uk-child-width-1-2@m">
        <div class="form-content uk-align-center uk-padding">
            <img src="../../../assets/images/icons/LogoSmartvoice/logo@2x.png">
            <p>
                Mejora la experiencia de compra en tu comercio electrónico con una solución inteligente para tu negocio.
            </p>
            <h5 class="uk-margin-remove-top green-text uk-text-bold">
                Beneficios
            </h5>
            <ul class="list-green uk-margin-remove-top">
                <li>Falicita la compra de tus clientes.</li>
                <li>Vende las 24 horas del día los 7 días de la semana.</li>
                <li>Mejora el alcance y rendimiento de tu negocio.</li>
            </ul>
            <div class="form-card uk-background-muted uk-padding-small uk-panel">
                <div class="uk-text-center">
                    <p class="title uk-margin-remove-top">Obten 30 días gratis</p>
                </div>
                <form class="uk-form-stacked" (ngSubmit)="contactForm(form)" #form="ngForm">
                    <div class="">
                        <label class="uk-form-label" for="name">Ingresa tu nombre completo*</label>
                        <div class="uk-form-controls">
                            <input gaEvent="fill_blur" gaCategory="REGISTRO_NAME" class="uk-input form-control" id="name" type="text" required name="name"
                                #name="ngModel" [(ngModel)]="message.name">
                        </div>
                    </div>

                    <div class="">
                        <label class="uk-form-label" for="email">Ingresa tu correo electrónico*</label>
                        <div class="uk-form-controls">
                            <input gaEvent="fill_blur" gaCategory="REGISTRO_EMAIL" class="uk-input form-control" id="email" type="email" required name="email"
                                #email="ngModel" [(ngModel)]="message.email">
                        </div>
                    </div>

                    <div class="">
                        <label class="uk-form-label" for="ocupation">¿Cuál es tu ocupación?</label>
                        <div class="uk-form-controls">
                            <input gaEvent="fill_blur" gaCategory="REGISTRO_OCUPATION" class="uk-input form-control" id="ocupation" type="text" name="ocupation"
                                #ocupation="ngModel" [(ngModel)]="message.ocupation">
                        </div>
                    </div>
                    <div class="uk-text-right">
                        <p class="dark uk-text-bold uk-text-small">* Campos obligatorios</p>
                    </div>
                    <div class="uk-text-center">
                        <button type="submit"
                            class="uk-button white-text green uk-border-pill uk-text-bold">Enviar</button>
                        <br>
                        <div style="height: 10px;"></div>
                        <a uk-toggle="target: #modal_aviso" class="uk-text-small green-text uk-margin-small-top">Aviso
                            de privacidad</a>
                    </div>
                </form>
            </div>
            <div class="uk-align-center uk-text-center">
                <p>Síguenos en nuestras redes</p>
                <div>
                    <a gaEvent="click" gaCategory="LINK_FACEBOOK" href="https://www.facebook.com/Smartvoicecommerce" target="_blank" class="uk-icon-button"
                        data-uk-icon="facebook"></a>
                    <a gaEvent="click" gaCategory="LINK_LINKEDIN" href="https://www.linkedin.com/showcase/smartvoice-commerce"
                        class="uk-margin-small-left uk-icon-button" target="_blank" data-uk-icon="linkedin"></a>
                </div>
            </div>
        </div>
    </div>
</div>
