import { Router } from '@angular/router';
import { HostListener, Component, OnDestroy, OnInit } from '@angular/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { CookieService } from 'ngx-cookie-service';

declare var UIkit: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit, OnDestroy{
  colortext = 'white-text';

  constructor( private router: Router, private $gaService: GoogleAnalyticsService, private cookieService: CookieService) { }

  ngOnInit(): void {
    const cookieExists = this.cookieService.check('cookies')
    if (!cookieExists) {
      var modal = document.getElementById("myModal");
      modal.style.display = "block";
    }
    
  }

  ngOnDestroy(): void {  }

  saveCookie(){
    this.cookieService.set( 'cookies', 'Acepted' );
  }

  @HostListener('window:scroll', ['$event']) onScrollEvent(){
    if (window.scrollY > 50) {
      this.changeColor('dark-text')
    } else {
      this.changeColor('text-white')
    }
  }

  changeColor(val:string){
    this.colortext = val;
  }

  toFaqs(){
    UIkit.offcanvas("#menu_left").hide();
    setTimeout(() => {
      this.router.navigate(['faqs']);
    }, 360);
  }

  toPrices(){
    UIkit.offcanvas("#menu_left").hide();
    setTimeout(() => {
      this.router.navigate(['prices']);
    }, 360);
  }

  setScrollTo(element: any) {
    console.log(element)
    UIkit.offcanvas("#menu_left").hide()
  }

  setClickEvent(rute: any, boton: any){
    var evento = ''
    if(rute == 'faqs'){
      evento = 'Va a preguntas frecuentes desde el boton ' + boton
    } else {
      evento = 'Va al registro desde el boton ' + boton
      this.$gaService.event('click', 'register', evento);
    }
    this.router.navigate([rute])
  }

}
