<!-- HEADER -->
<div class="top-wrap uk-position-relative uk-light uk-light" id="top">
  <div class="nav"
    data-uk-sticky="cls-active: uk-light white uk-box-shadow-medium; top: 150; animation: uk-animation-slide-top">
    <div class="banner-container">
      <div class="container-letter-register">
        ¡Regístrate ahora y obtén 1 mes gratis!
     </div>
      <a href="/ventas" class="button-start">Empezar</a>
    </div>
    <nav class="uk-navbar uk-navbar-container uk-navbar-transparent" data-uk-navbar>
      <div class="uk-navbar-left  navi">
        <div class="uk-navbar-item uk-margin-left navi">
          <a href="#menu_left" uk-toggle><span class="{{colortext}}" uk-navbar-toggle-icon></span></a>
          <!--<a class="uk-hidden@m" href="#menu_left" uk-toggle><span class="{{colortext}}" uk-navbar-toggle-icon></span></a>-->
          <div class="uk-container">
            <a class="uk-logo" title="Smartvoice" routerLink="/" routerLinkActive="active"><img
                src="../assets/images/icons/LogoSmartvoice/logo@3x.png" alt="Smartvoice"></a>
          </div>
        </div>
      </div>

      <!-- <div class="uk-navbar-right uk-margin-large-right ">
                <ul class="uk-navbar-nav uk-visible@m" uk-scrollspy-nav="closest: li; scroll: true">
                    <li class="item">
                        <a gaEvent="click" gaCategory="MENU_HOME" class="{{colortext}}" uk-scroll href="#top" (click)="setScrollTo('home')"><span>Home</span></a></li>
                    <li class="item">
                        <a gaEvent="click" gaCategory="MENU_CARACTERISTICAS" class="{{colortext}}" uk-scroll href="#caracteristicas" (click)="setScrollTo('caracteristicas')"><span>Características</span></a></li>
                    <li class="item">
                        <a gaEvent="click" gaCategory="MENU_COMOFUNCIONA" class="{{colortext}}" uk-scroll href="#function" (click)="setScrollTo('como funciona')">
                            <span>¿Cómo funciona?</span></a></li>
                    <li class="item">
                        <a gaEvent="click" gaCategory="MENU_FAQS" class="{{colortext}}" href="/faqs">Preguntas frecuentes</a>
                    </li>
                    <li class="item">
                        <a gaEvent="click" gaCategory="MENU_PRICE" class="{{colortext}}" href="/prices">Precios</a>
                    </li>
                </ul>
            </div> -->



      <div id="menu_left" uk-offcanvas="overlay: true; mode: push">
        <div class="uk-offcanvas-bar">
          <div class="header">
            <p>Menú</p>
            <button class="uk-offcanvas-close " type="button" uk-close></button>
          </div>
          <ul class="uk-nav uk-nav-primary" uk-scrollspy-nav="closest: li; scroll: true">
            <li><a gaEvent="click" gaCategory="MENU_HOME" uk-scroll href="#top" (click)="setScrollTo('home')"><i
                  class="uk-margin-right uil uil-home"></i>
                <span class="uk-margin-left uk-text-small uk-text-bold">Home</span> </a></li>
            <li><a gaEvent="click" gaCategory="MENU_CARACTERISTICAS" uk-scroll href="#caracteristicas"
                (click)="setScrollTo('caracteristicas')"><i class="uk-margin-right uil uil-clipboard-notes"></i> <span
                  class="uk-margin-left uk-text-small uk-text-bold">Características</span></a></li>
            <li><a gaEvent="click" gaCategory="MENU_COMOFUNCIONA" uk-scroll href="#function"
                (click)="setScrollTo('como funciona')"><i class="uk-margin-right uil uil-question-circle"></i>
                <span class="uk-margin-left uk-text-small uk-text-bold">¿Cómo funciona?</span></a></li>
            <li><a gaEvent="click" gaCategory="MENU_FAQS" (click)="toFaqs()"><i
                  class="uk-margin-right uil uil-comment-alt-notes"></i><span
                  class="uk-margin-left uk-text-small uk-text-bold">Preguntas frecuentes</span></a>
            </li>
            <li class="item">
              <a gaEvent="click" gaCategory="MENU_PRICE" (click)="toPrices()"><i
                  class="uk-margin-right uil uil-coins"></i><span
                  class="uk-margin-left uk-text-small uk-text-bold">Precios</span></a>
            </li>
            <li class="item">
              <a gaEvent="click" gaCategory="MENU_CONTACT" href="/register"><i
                  class="uk-margin-right uil uil-comment-alt"></i><span
                  class="uk-margin-left uk-text-small uk-text-bold">Contactanos</span></a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
  <div class="uk-cover-container uk-light uk-flex uk-flex-middle top-wrap-height" style="height: 100vh;">
    <div class=" uk-flex-auto top-container uk-position-relative" data-uk-parallax="y: 0,50; easing:0; opacity:0.2">
      <div class="uk-width-1-3@s uk-margin-large-left navi"
        data-uk-scrollspy="cls: uk-animation-slide-right-medium; target: > *; delay: 150">
        <h2 class="uk-margin-remove-top  uk-margin-large-right">
          Smartvoice es el primer software en México que permite utilizar asistentes de voz y chatbot para e-commerce
          sin depender de un app o web. </h2>
        <a href="/ventas" title="SOLICITA 30 DÍAS TOTALMENTE GRATIS"
          class="uk-button green uk-border-pill uk-text-bold" data-uk-scrollspy-class="uk-animation-fade">REGISTRARME AHORA</a>
      </div>
    </div>

    <img
      src="../assets/images/images/woman-tablet-escritorio/woman-sitting-on-sofa-while-looking-at-phone-with-laptop-on-920382@3x.jpg"
      data-sizes="100vw"
      data-src="../assets/images/images/woman-tablet-escritorio/woman-sitting-on-sofa-while-looking-at-phone-with-laptop-on-920382@3x.jpg"
      data-uk-cover data-uk-img>
  </div>
  <div class="uk-position-bottom-center uk-position-medium uk-position-z-index uk-text-center">
    <a href="#content" data-uk-scroll="duration: 1500" uk-scroll data-uk-icon="icon: arrow-down; ratio: 2"></a>
  </div>
</div>
<div class="banner-test-app">DESCUBRELO MUY PRONTO</div>
<!--HEADER-->

<!-- BENEFICIOS -->
<section class="uk-section uk-section-default content-circle" id="content" style="padding-top: 40px">
  <div class="uk-section">
    <div class="uk-container uk-text-center">
      <h2 class="black" style="font-weight: bold;">Plataforma de comercio electrónico con voz</h2>
      <h3>Nuestro objetivo principal es potenciar el crecimiento y expansión de tu negocio, a través de una solución
        innovadora de bajo costo y con el mínimo esfuerzo.</h3>
    </div>
  </div>

  <div class="uk-container">
    <div class="uk-grid uk-grid-large uk-child-width-1-3@m" data-uk-grid
      data-uk-scrollspy="target: > div; delay: 150; cls: uk-animation-slide-bottom-medium; repeat: true">
      <div>
        <div class="uk-text-center">
          <img src="../assets/images/beneficios/computadoraycelular/undraw-progressive-app-m-9-ms-1.png" width="290"
            height="290"
            data-src="../assets/images/beneficios/computadoraycelular/undraw-progressive-app-m-9-ms-1@2x.png"
            data-uk-img alt="Image">
          <h4 class="uk-margin-small-bottom uk-margin-top uk-margin-remove-adjacent black-text uk-text-bold">
            Plataforma</h4>
        </div>
        <ul class="list uk-list uk-list-disc">
          <li>Multicanal: Gran variedad de canales para vender.</li>
          <li>Disponibilidad continua de la plataforma: 24 horas, 7 días, los 365 días del año.</li>
          <li>Reducción de tiempo y esfuerzo al montar un nuevo canal de venta.</li>
          <li>Plataforma en esquema SaaS.</li>
        </ul>
      </div>
      <div>
        <div class="uk-text-center">
          <img src="../assets/images/beneficios/chicaycelular/undraw-proud-self-1-ddv.png"
            data-src="../assets/images/beneficios/chicaycelular/undraw-proud-self-1-ddv@2x.png" data-uk-img alt="Image">
          <h4 class="uk-margin-small-bottom uk-margin-top uk-margin-remove-adjacent black-text uk-text-bold">
            Clientes</h4>
        </div>
        <ul class="list uk-list uk-list-disc">
          <li>Interacción natural y cómoda con tus clientes.</li>
          <li>Tus clientes solo tienen que hablar con su asistente de voz.</li>
          <li>Pensamos en tus clientes con limitaciones físicas y visuales.</li>
          <li>Tus clientes llevan tu tienda en su bolsillo. Solo necesitan conocer tu número teléfonico, creando un
            canal directo de conversación con tu marca.</li>
        </ul>
      </div>
      <div>
        <div class="uk-text-center">
          <img src="../assets/images/beneficios/celular/undraw-sync-4-xlc-6.png"
            data-src="../assets/images/beneficios/celular/undraw-sync-4-xlc-6@2x.png" data-uk-img alt="Image">
          <h4 class="uk-margin-small-bottom uk-margin-top uk-margin-remove-adjacent black-text uk-text-bold">
            Compatibilidad</h4>
        </div>
        <ul class="list uk-list uk-list-disc">
          <li>Se integra a medios de pago electrónicos.</li>
          <li>Integración a plataformas de mensajería.</li>
          <li>Creación o integración con catálogos de productos.</li>
          <li>Creación o integración con inventarios.</li>
          <li>Integración con los asistentes de voz más populares.</li>
          <li>Integración con los asistentes de chat más populares.</li>
        </ul>
      </div>
    </div>
  </div>
  <div id="caracteristicas"></div>
</section>
<!-- /BENEFICIOS -->

<!-- CARACTERISTICAS -->
<section class="uk-section uk-section-default">
  <div class="uk-container uk-container-xsmall uk-text-center uk-section uk-padding-remove-top">
    <h2 class="uk-margin-remove uk-h1">Características principales</h2>
    <h5 class="">Haz que tu negocio fluya mejor con inteligencia conversacional y facilita la gestión de tu negocio.
    </h5>
  </div>
  <div class="uk-container">
    <div uk-slider="autoplay: true; finite: false; autoplay-interval: 2000; pause-on-hover: true">
      <div class="uk-position-relative">
        <div class="uk-slider-container uk-light">
          <ul class="uk-slider-items uk-child-width-1-1 uk-child-width-1-1@s uk-child-width-1-1@m">
            <li>
              <div data-uk-slider-parallax="opacity: 0.2,1,0.2">
                <div class="uk-container">
                  <div class="uk-grid uk-child-width-1-2@l uk-flex-middle">
                    <div class="img_caracteristicas">
                      <img
                        src="../../../assets/images/caracteristicas/modeladopredictivo/undraw-add-to-cart-vkjp-2@2x.png"
                        width="450px" height="450px"
                        data-src="../assets/images/caracteristicas/modeladopredictivo/undraw-add-to-cart-vkjp-2@2x.png"
                        data-uk-img alt="Image">
                    </div>
                    <div>
                      <h2 class="uk-margin-small-top uk-h1 dark-text">Modelado Predictivo</h2>
                      <p class="subtitle-text dark-text">
                        Smartvoice identifica patrones convirtiendo los datos en predicciones en base a gustos o
                        preferencias de búsquedas e historial de compras de tus clientes.
                      </p>
                      <ul class="list uk-list uk-list-disc">
                        <li>A través del bot se obtienen respuestas instantáneas.</li>
                        <li>Los clientes realizan sus pedidos desde la plataforma que elijan como su favorita.</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div data-uk-slider-parallax="opacity: 0.2,1,0.2">
                <div class="uk-container">
                  <div class="uk-grid uk-child-width-1-2@l uk-flex-middle">
                    <div class="img_caracteristicas">
                      <img src="../../../assets/images/caracteristicas/omnicanalidad/group-3@2x.png" width="450px"
                        height="450px" data-src="../assets/images/caracteristicas/omnicanalidad/group-3@2x.png"
                        data-uk-img alt="Image">
                    </div>
                    <div>
                      <h2 class="uk-margin-small-top uk-h1 dark-text">Omnicanalidad</h2>
                      <p class="subtitle-text dark-text">
                        Es la evolución de la multicanalidad, con el propósito de ofrecer una única experiencia con un
                        enfoque multicanal de las ventas, permitiendo a los clientes realizar compras a través de casi
                        todas las vías posibles desarrolladas.
                      </p>
                      <ul class="list uk-list uk-list-disc">
                        <li>
                          Te permite integrar una gran variedad de canales para vender: Whatsapp, Facebook Messenger,
                          Telegram, Amazon Alexa, Google Assistant en Google Home, Teléfonos Android y Android Auto.
                        </li>
                      </ul>
                      <p class="subtitle-text dark-text">
                        Por ejemplo, comenzaste a comprar en tu asistente de Amazon Alexa sin embargo tienes que salir
                        de casa y quieres completarla por Facebook Messenger, la solución de Smartvoice te permitirá
                        completar una compra de forma sencilla, rápida, y sin salir de
                        la aplicación.

                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div data-uk-slider-parallax="opacity: 0.2,1,0.2">
                <div class="uk-container">
                  <div class="uk-grid uk-child-width-1-2@l uk-flex-middle">
                    <div class="img_caracteristicas">
                      <img src="../../../assets/images/caracteristicas/adminecomerce/group-3@2x.png" width="450px"
                        height="450px" data-src="../assets/images/caracteristicas/adminecomerce/group-3@2x.png"
                        data-uk-img alt="Image">
                    </div>
                    <div>
                      <h2 class="uk-margin-small-top uk-h1 dark-text">Administración de tu eCommerce
                      </h2>
                      <ul class="list uk-list uk-list-disc">
                        <li>
                          Podrás observar las ventas realizadas por tienda, sucursal y por canal de Smartvoice, Amazon
                          Alexa, Whatsapp, Facebook Messenger, Google Assistant.
                        </li>
                        <li>
                          Podrás visualizar el seguimiento de los pedidos de tus clientes hasta que obtengan su
                          producto.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>

        <div class="uk-light uk-hidden@l uk-hidden@xl">
          <a class="uk-position-center-left uk-slidenav-large uk-position-small green-text" href="#"
            uk-slidenav-previous uk-slider-item="previous"></a>
          <a class="uk-position-center-right uk-slidenav-large uk-position-small green-text" href="#" uk-slidenav-next
            uk-slider-item="next"></a>
        </div>

        <div class="uk-hidden@s">
          <a class="uk-position-center-left-out uk-slidenav-large uk-position-small green-text" href="#"
            uk-slidenav-previous uk-slider-item="previous"></a>
          <a class="uk-position-center-right-out uk-slidenav-large uk-position-small green-text" href="#"
            uk-slidenav-next uk-slider-item="next"></a>
        </div>

      </div>
      <ul class="uk-slider-nav uk-dotnav uk-flex-center uk-margin"></ul>

    </div>
    <div id="function"></div>
  </div>

</section>
<!-- /CARACTERISTICAS -->

<!-- COMO FUNCIONA -->
<section class="uk-section uk-section-default content-circle">
  <div class="uk-container uk-container-xsmall uk-text-center uk-section uk-padding-remove-top">
    <h2 class="uk-margin-remove uk-h1">¿Cómo funciona?</h2>
    <h5 class="">Ya te hemos informado sobre Smartvoice, sus características y beneficios. Ahora queremos mostrarte cómo
      puede funcionar en tu negocio y cómo tus clientes pueden interactuar a través de él. </h5>
  </div>
  <div class="uk-container">
    <div class="uk-section uk-section-small uk-padding-remove-top">
      <ul class="uk-subnav uk-subnav-pill uk-flex uk-flex-center uk-text-center"
        data-uk-switcher="connect: .uk-switcher; animation: uk-animation-fade">
        <div class="uk-hidden@s" style="width: 2px;"></div>
        <li><a gaEvent="click" gaCategory="COMOFUNCIONA_VOZ" class="uk-border-pill" href="#">Comprando por voz</a></li>
        <div class="uk-hidden@s" style="height: 50px;"></div>
        <li><a gaEvent="click" gaCategory="COMOFUNCIONA_MSG" class="uk-border-pill" href="#">Comprando por mensaje</a>
        </li>
      </ul>
    </div>
    <ul class="uk-switcher uk-margin">
      <li>
        <div class="uk-grid uk-grid-large" data-uk-grid
          data-uk-scrollspy="target: > div > div; delay: 150; cls: uk-animation-slide-bottom-medium">

          <div class="uk-grid uk-child-width-1-3@m">
            <div class="uk-text-center">
              <img src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                data-src="../../../assets/images/images/voz-1.svg" data-uk-img alt="Image">
              <h4 class="uk-margin-small-bottom uk-margin-top uk-margin-remove-adjacent">Activa la skill o servicio de
                tu asistente de voz con tu teléfono inteligente.</h4>
            </div>
            <div class="uk-text-center">
              <img src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                data-src="../../../assets/images/images/voz-2.svg" data-uk-img alt="Image">
              <h4 class="uk-margin-small-bottom uk-margin-top uk-margin-remove-adjacent">Invoca la skill o servicio de
                tu asistente de voz.</h4>
            </div>
            <div class="uk-text-center">
              <img src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                data-src="../../../assets/images/images/voz-3.svg" data-uk-img alt="Image">
              <h4 class="uk-margin-small-bottom uk-margin-top uk-margin-remove-adjacent">Conversa con tu asistente de
                voz sobre el producto que deseas adquirir.
              </h4>
            </div>
          </div>
          <div class="uk-grid uk-child-width-1-2@m">
            <div class="uk-text-center">
              <img src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                data-src="../../../assets/images/images/voz-4.svg" data-uk-img alt="Image">
              <h4 class="uk-margin-small-bottom uk-margin-top uk-margin-remove-adjacent">Confirma que el producto que te
                ofrece tu asistente de voz es el que deseas.</h4>
            </div>
            <div class="uk-text-center">
              <img src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                data-src="../../../assets/images/images/voz-5.svg" data-uk-img alt="Image">
              <h4 class="uk-margin-small-bottom uk-margin-top ">Disfruta el producto que adquiriste por medio de tu
                asistente de voz.
              </h4>
            </div>
          </div>
        </div>
      </li>
      <li>
        <div class="uk-grid uk-grid-large" data-uk-grid
          data-uk-scrollspy="target: > div > div; delay: 150; cls: uk-animation-slide-bottom-medium">
          <div class="uk-grid uk-child-width-1-2@m">
            <div class="uk-text-center">
              <img src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                data-src="../../../assets/images/images/chat-1.svg" data-uk-img alt="Image">
              <h4 class="uk-margin-small-bottom uk-margin-top uk-margin-remove-adjacent">Registra el número del chatbot
                en tu aplicación de mensajería como agregas a cualquier contacto nuevo.
              </h4>

            </div>
            <div class="uk-text-center">
              <img src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                data-src="../../../assets/images/images/chat-2.svg" data-uk-img alt="Image">
              <h4 class="uk-margin-small-bottom uk-margin-top uk-margin-remove-adjacent">Mensajea con el chatbot sobre
                el producto que deseas adquirir.
              </h4>
            </div>
          </div>
          <div class="uk-grid uk-child-width-1-2@m">
            <div class="uk-text-center">
              <img src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                data-src="../../../assets/images/images/chat-3.svg" data-uk-img alt="Image">
              <h4 class="uk-margin-small-bottom uk-margin-top uk-margin-remove-adjacent">Disfruta el producto que
                adquiriste por medio del chatbot.
              </h4>
            </div>
            <div class="uk-text-center">
              <img src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                data-src="../../../assets/images/images/chat-4.svg" data-uk-img alt="Image">
              <h4 class="uk-margin-small-bottom uk-margin-top uk-margin-remove-adjacent">Confirma que el producto que te
                ofrece el chatbot es el que deseas.
              </h4>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</section>
<!-- /COMO FUNCIONA-->

<!--DEMO -->
<section class="uk-section uk-section-secondary uk-section-large" style="background-color: #8f8e93;">
  <div class="uk-container">
    <div class="uk-grid uk-child-width-1-2@l uk-flex-middle">
      <div uk-scrollspy="cls: uk-animation-slide-left; repeat: true">
        <h2 class="uk-margin-small-top uk-h1">Incrementa tus ventas sin necesidad de una página web o aplicación móvil.
        </h2>
        <p class="subtitle-text dark-text">
          Se parte del pre lanzamiento y obtén acceso anticipado para descubrir todo el poder de Smartvoice. 
          Sólo llena el registro para ser uno de los cien comercios seleccionados y obtén una prueba de 30 días totalmente gratis.
        </p>
        <a href="/ventas"
          class="uk-visible@m uk-visible@s uk-visible@l  uk-button green uk-border-pill uk-text-bold">REGISTRARME AHORA</a>
        <a (click)="setClickEvent('register', 'inferior en la pantalla principal')" style="margin-bottom: 25px;"
          class="uk-hidden@xl uk-hidden@l uk-float-right  uk-button green uk-border-pill uk-text-bold">OBTENER
          30 DÍAS GRATIS</a>
      </div>

      <div uk-scrollspy="cls: uk-animation-slide-right; repeat: true">
        <img src="../assets/images/images/undraw_web_shopping_dd4l.svg" width="500px" height="500px"
          data-src="../assets/images/images/undraw_web_shopping_dd4l.svg" data-uk-img alt="Image">
      </div>
    </div>
  </div>
</section>
<!--/DEMO -->
<app-aviso-privacidad></app-aviso-privacidad>
<app-aviso-cookies></app-aviso-cookies>
<div id="myModal" class="modal">
  <div class="modal-content">
    <div uk-alert class="content-notification white">
      <!--<button gaEvent="click" gaCategory="COOKIES_ACCEPTED" class="uk-alert-close uk-button uk-button-secondary" style="background-color: black !important; color: #fff !important;">Aceptar</button>
            -->
      <button (click)="saveCookie()" gaEvent="click" gaCategory="COOKIES_ACCEPTED"
        class="uk-alert-close uk-button green uk-border-pill white-text" style="opacity: 1"
        type="button">Aceptar</button>
      <h3>Aviso de uso de Cookies</h3>
      <p>Smartvoice utiliza cookies para garantizar que le brindamos la mejor experiencia en nuestro sitio web. Al
        navegar aceptas nuestras <a uk-toggle="target: #modal_aviso_cookies">política de cookies.</a>
      </p>
    </div>
  </div>
</div>
<!--FOOTER-->
<footer class="uk-section uk-section-secondary uk-padding-remove-bottom">
  <div class="uk-container">
    <div class="uk-grid uk-grid-large" data-uk-grid>
      <div class="uk-width-1-2@m">
        <img src="../assets/images/icons/Footer/LogoSmartvoice/sv-logo-color.png" alt="">

        <p>No dudes en contactarnos para obtener una atención más personal y a la vez para aclarar cualquier duda
          relacionada con Smartvoice.</p>
        <div>
          <a gaEvent="click" gaCategory="CONTACTO_EMAIL" href="mailto:contacto@smartvoice.com.mx"
            class="uk-button uk-button-text uk-text-lowercase"><span class="uk-margin-right" uk-icon="mail"></span>
            contactanos@smartvoice.com.mx</a> <br><br>
          <a gaEvent="click" gaCategory="CONTACTO_PHONE" href="tel:800-65698842" class="uk-button uk-button-text"><span
              class="uk-margin-right" uk-icon="receiver"></span>800-65698842</a>
        </div>
        <br>
        <div>
          <a gaEvent="click" gaCategory="LINK_FACEBOOK" href="https://www.facebook.com/Smartvoicecommerce"
            target="_blank" class="uk-icon-button" data-uk-icon="facebook"></a>
          <a gaEvent="click" gaCategory="LINK_LINKEDIN" href="https://www.linkedin.com/showcase/smartvoice-commerce"
            class="uk-margin-small-left uk-icon-button" target="_blank" data-uk-icon="linkedin"></a>
        </div>
      </div>
      <div class="uk-width-1-6@m">
        <h5>PRODUCTO</h5>
        <ul class="uk-list">
          <li><a gaEvent="click" gaCategory="FOOTER_CARACTERISTICAS" href="#caracteristicas"
              uk-scroll>Características</a></li>
          <li><a gaEvent="click" gaCategory="FOOTER_FAQS" (click)="setClickEvent('faqs', 'footer')">Preguntas
              frecuentes</a></li>
          <li><a gaEvent="click" gaCategory="FOOTER_STORE" href="store.smartvoice.com.mx" target="_blank">Compra
              Smartvoice</a></li>
        </ul>
      </div>
      <div class="uk-width-1-6@m">
        <h5>COMPAÑIA</h5>
        <ul class="uk-list">
          <li><a gaEvent="click" gaCategory="FOOTER_POLITICAS" uk-toggle="target: #modal_aviso">Aviso de privacidad</a>
          </li>
        </ul>
      </div>
      <div class="uk-width-1-6@m">
        <h5>LINKS</h5>
        <ul class="uk-list">
          <li><a gaEvent="click" gaCategory="FOOTER_HOME" href="#top" uk-scroll>Home</a></li>
          <li><a gaEvent="click" gaCategory="FOOTER_COMOFUNCIONA" href="#function" uk-scroll>¿Cómo funciona?</a></li>
          <li><a gaEvent="click" gaCategory="FOOTER_REGISTER" href="/register" uk-scroll>Contactanos</a></li>
        </ul>
      </div>
    </div>
  </div>

  <div class="uk-text-center uk-padding uk-padding-remove-horizontal">
    <span class="uk-text-small uk-text-muted">© 2022 Smartvoice - <a gaEvent="click" gaCategory="LINK_ONIKOM"
        href="https://onikom.com.mx/" target="_blank">Onikom System</a> -</span>
  </div>
</footer>