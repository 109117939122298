import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SendEmailService {
  url = 'https://us-central1-voicecommerce-271620.cloudfunctions.net/smartvoice-api/correos/contact'
  
  constructor(private _http: HttpClient) { }
  
  send(body: any) {
    return this._http.post(this.url, body);
  }

}
