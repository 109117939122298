<div id="modal_aviso" uk-modal>
    <div class="uk-modal-dialog">
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="uk-modal-body">
            <h2 class="uk-modal-title">Aviso de privacidad</h2>
            <div uk-overflow-auto>
                <p class="dark">
                    ¿Quiénes somos? Oni kommon S.A. de C.V. con domicilio en calle Hamburgo 70 int. 300-301, colonia Juarez, ciudad CDMX, municipio o delegación Cuauhtémoc, C.P. 06600, en la entidad de CDMX, país Mexico, y portal de internet <a href="https://www.onikom.com.mx" target="_blank">www.onikom.com.mx</a>, es el responsable del uso y protección de sus datos personales, y al respecto le informamos lo siguiente:
                </p>
                <p class="dark">
                    ¿Para qué fines utilizaremos sus datos personales? Los datos personales que recabamos de usted, los utilizaremos para las siguientes finalidades:
                </p>
                <ul class="list">
                    <li>Seguimiento para el uso de sus 30 días de prueba gratuitos.</li>
                    <li>Prospección comercial.</li>
                </ul>
            </div>
            <br>
            <div class="uk-text-right">
                <button class="uk-button green uk-border-pill white-text uk-modal-close" type="button">Aceptar</button>
            </div>
        </div>
    </div>
</div>