import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { SendEmailService } from '../services/send-email.service'

declare var UIkit: any;

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})

export class RegisterComponent implements OnInit {
  sendEmail = false
  message: MessageC

  constructor (public email: SendEmailService, private router: Router, private $gaService: GoogleAnalyticsService) {
    this.message = new MessageC("","","");
  }

  ngOnInit(): void { }

  openNav(){
    UIkit.offcanvas("#menu_left").show();
  }

  closeNav(){
    UIkit.offcanvas("#menu_left").hide();
  }

  toFaqs(){
    UIkit.offcanvas("#menu_left").hide();
    setTimeout(() => {
      this.router.navigate(['questions']);
    }, 360);
  }

  toPrices(){
    UIkit.offcanvas("#menu_left").hide();
    setTimeout(() => {
      this.router.navigate(['prices']);
    }, 360);
  }

  contactForm(form: any) {
    if (this.message.name != '' && this.message.email != '') {
      UIkit.modal("#modal").show()
      this.email.send(form.value).subscribe(() => {
        this.$gaService.event('submit', 'user_register_form', 'Se registro un nuevo prospecto');
        setTimeout(() => {
          this.sendEmail = true
          this.message = new MessageC("","","");
          form.reset()
        }, 1500);
      }) 
    } else {
      UIkit.notification("Por favor llene los campos obligatorios", {pos: 'top-right'})
    }
  }

}

export class MessageC {
  name:string;
  email:string;
  ocupation:string
  
  constructor(
      name:string,
      email:string,
      ocupation:string
  ){
    this.name = name;
    this.email = email;
    this.ocupation = ocupation;
  }
}