import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { FaqsComponent } from './faqs/faqs.component';
import { RegisterComponent } from './register/register.component';
import { PriceComponent } from './price/price.component'

// SERVICES
import { SendEmailService } from './services/send-email.service'
import { AvisoPrivacidadComponent } from './aviso-privacidad/aviso-privacidad.component'
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';

import { environment } from 'src/environments/environment';
import { AvisoCookiesComponent } from './aviso-cookies/aviso-cookies.component';

import { CookieService } from 'ngx-cookie-service';
import { VentasComponent } from './ventas/ventas.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    FaqsComponent,
    RegisterComponent,
    AvisoPrivacidadComponent,
    AvisoCookiesComponent,
    PriceComponent,
    VentasComponent
  ],
  imports: [
    FormsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgxGoogleAnalyticsModule.forRoot(environment.googletag),
  ],
  providers: [SendEmailService , CookieService],
  bootstrap: [AppComponent]
})
export class AppModule { }
