import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

declare var UIkit: any;

@Component({
  selector: 'app-price',
  templateUrl: './price.component.html',
  styleUrls: ['./price.component.css']
})
export class PriceComponent implements OnInit {

  constructor(private router: Router) { }

  toFaqs(){
    UIkit.offcanvas("#menu_left").hide();
    setTimeout(() => {
      this.router.navigate(['faqs']);
    }, 360);
  }

  openNav(){
    UIkit.offcanvas("#menu_left").show();
  }

  closeNav(){
    UIkit.offcanvas("#menu_left").hide();
  }

  ngOnInit(): void {
  }
  toPrices(){
    UIkit.offcanvas("#menu_left").hide();
    setTimeout(() => {
      this.router.navigate(['prices']);
    }, 360);
  }
}
